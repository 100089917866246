<template>
  <div>
    <v-container v-if="componentKey" class="container--fluid">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col cols="12" class="pl-0 pr-0 pb-1">
              <v-row>
                <v-spacer />

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="santralModel"
                    :items="santralDataList"
                    label="Santral"
                  />
                </v-col>

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="measureSelect"
                    :items="measures"
                    label=""
                  />
                </v-col>
                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker v-model="startDate" no-title scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="3" sm="1" md="1" class="pr-3">
                  <v-btn color="primary" @click="getData"> Filtrele </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col :key="componentKey" cols="12">
              <echart
                id="heatmapsHourlySS"
                class-name="widget-content"
                :height="height"
                width="100%"
                :chart-data="HeatmapData"
              />
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
 
import * as Heatmap from "@/api/CombinerBox/Heatmap";
import moment from "moment";
import i18n from "@/locale/index";
import Echart from "@/views/components/Echart/Chart.vue";
import { getStringboxDataList } from "@/api/Request/stringbox";

export default {
  name: "Dashboard",
  components: {
    Echart, 
  },
  data: () => ({
    startDate: moment().format("YYYY-MM-DD"),
    finishDate: moment().format("YYYY-MM-DD"),

    menu: false,
    modal: false,
    menu2: false,
    mina: "",
    maxa: "",
    dialog: false,
    nowDate: new Date().toISOString().slice(0, 10),
    menu1: false,
    minDate: "",
    daterangetext: i18n.t("Tarih Seç"),
    selecteditemstext: "",
    mindate: "2010",
    all: i18n.t("All"),
    dateRules: [(v) => !!v || "Date is required"],
    dates: [],
    measureList: [],
    santral_items: [],
    minutesDailyEnergydate: new Date().toISOString().substr(0, 10),
    measureSelect: "DCCurrent",
    componentKey: 1,
    title: "",
    santralModel: "",
    inverterList: [],
    height: "",
    HeatmapData: {},
  }),
  computed: {
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;
      return santralDataList;
    },
    measures() {
      const { stringboxMeasureList } = this.$store.getters;
      if (Object.keys(stringboxMeasureList).length > 0) {
        return stringboxMeasureList;
      }
    },

    dateRangeText() {
      if (this.dates.length > 1) {
        const dateArr = this.dates;
        if (dateArr[0] > dateArr[1]) {
          this.selecteditemstext = `${dateArr[1]} ~ ${dateArr[0]}`;
        } else {
          this.selecteditemstext = `${dateArr[0]} ~ ${dateArr[1]}`;
        }
      } else {
        this.selecteditemstext = this.dates[0];
      }

      return this.selecteditemstext;
    },
    computedDateFormatted() {
      return this.formatDate();
    },
    maxDate() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  watch: {
    santralModel() {
      if (this.santralModel !== null && this.santralModel !== undefined) {
        this.getData();
      }
    },
    measureSelect() {
      // this.getData();
    },
  },
  created() {},
  mounted() {
    this.startDate = moment().format("YYYY-MM-DD");
    this.finishDate = moment().format("YYYY-MM-DD");
    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    let k = 0;
    function myTimer1() {
      if (k > 30) {
        clearInterval(myVar1);
      }

      k += 1;
      const { santral } = self.$store.getters.selectedSantralModel;

      if (santral !== undefined) {
        self.santralModel = santral;
        clearInterval(myVar1);
      }
    }
  },

  methods: {
    getData() {
      this.title = `${moment()
        .add(-6, "day")
        .format("YYYY-MM-DD")} / ${moment().format("YYYY-MM-DD")} ${i18n.t(
        this.measureSelect
      )}`;

      this.finishDate = this.startDate;
      const { santralStringboxData } = this.$store.getters;

      const stringboxNameList = {};

      Object.keys(santralStringboxData[this.santralModel]).forEach((item) => {
        const k = santralStringboxData[this.santralModel][item].STRINGBOX;
        const v = santralStringboxData[this.santralModel][item].STRINGBOXNAME;
        stringboxNameList[k] = v;
      });

      const string_List = Object.keys(santralStringboxData[this.santralModel]);
      const stringboxCount = santralStringboxData[this.santralModel].length;
      this.height = "400px";
      if (stringboxCount < 11) {
        this.height = "400px";
      }
      if (stringboxCount > 10 && stringboxCount < 15) {
        this.height = "600px";
      }

      if (stringboxCount > 15 && stringboxCount < 20) {
        this.height = "800px";
      }

      if (stringboxCount > 20) {
        this.height = "900px";
      }
      // eslint-disable-next-line no-plusplus
      this.componentKey++;
      const formdata = {
        condiniton: {
          santral: this.santralModel,
          startDate: this.startDate,
          finishDate: this.finishDate,
          measures: this.measureSelect,
          STRINGBOX: string_List,
          maxcount: 5000,
        },
      };
      const steptime = this.$store.getters.stepTime;
      console.log(this.measures);
      let sMeasure;
      this.measures.forEach((v) => {
        console.log(v);
        if (this.measureSelect === v.value) {
          sMeasure = v;
        }
      });
      console.log(sMeasure);
      const tempdata = getStringboxDataList(formdata);
      tempdata.then((_raw) => {
        const temp = Heatmap.default.heatmapWeeklyHourMean(
          _raw,
          sMeasure,
          stringboxNameList,
          steptime,
        );
        temp.then((res) => {
          this.HeatmapData = res;
        });
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },

    onDateSelected(daterange) {
      this.finishDate = moment(daterange[1]._d).format("YYYY-MM-DD");
      this.startDate = moment(daterange[0]._d).format("YYYY-MM-DD");
      const alrt = this.select_filter();

      if (alrt != undefined) {
        alert(alrt);
      }
    },
    cancelF() {
      this.menu = false;
      this.daterangetext = i18n.t("Date Range");
      this.$refs.menu.save(this.dates);
    },
    save(date) {
      this.daterangetext = `${i18n.t("Date Range")} ${i18n.t(
        this.selectcolumn
      )}`;
      this.$refs.menu.save(date);
      this.loading = true;
      const selectdate = this.dates;

      if (date.length < 2) {
        this.finishDate = selectdate[0];
        this.startDate = selectdate[0];
      } else if (selectdate[0] > selectdate[1]) {
        this.finishDate = selectdate[0];
        this.startDate = selectdate[1];
      } else {
        this.finishDate = selectdate[1];
        this.startDate = selectdate[0];
      }
    },
  },
};
</script>

<style scoped>
#heatmapsHourlypage {
  position: relative;
  height: 350px;
  margin: 1px;
  padding: 1px;
}

#textrotate {
  text-align: center;
}

#heatmapsHourly {
  height: 95%;
}
</style>
